import { ENVIRONMENT } from "../../environment";

export class WhitepaperService {
    public async WhitepaperSender(name: string, fileName: string, email: string, title: string) {

        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            `Bearer ${process.env.REACT_APP_WHITEPAPER_TOKEN}`
        );
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "name": name,
            "email": email,
            "whitePaperId": fileName,
            "title": title
        });

        try {
            const response = await fetch(ENVIRONMENT.baseUrl + ENVIRONMENT.whitepaperSenderUrl, 
            {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            }
            );

            return response;

        } catch (error) {
            // console.error('Error fetching data:', error);
        }

    }

}
