import { ENVIRONMENT } from "../../environment";

export class LocationService {
    public async fetchIp() {
        try {
            const response = await fetch(ENVIRONMENT.regionUrl);
            const json = await response.json();
            return json;

        } catch (error) {
            // console.error('Error fetching data:', error);
        }
    }
}