import { ENVIRONMENT } from "../../environment";

// Define the type for the JSON response from the API
interface JsonResponse {
    code?: string;
    [key: string]: any; // To accommodate any additional fields in the response
}

// Define the type for the result of the validateEmail method
interface EmailValidationResult {
    response: Response;
    jsonResponse?: JsonResponse; // jsonResponse is optional
}

export class EmailValidatorService {
    public async validateEmail(email: string): Promise<EmailValidationResult> {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ "emailId": email });

        try {
            const res = await fetch(`${ENVIRONMENT.baseUrl}${ENVIRONMENT.emailValidator}`, {
                headers: myHeaders,
                method: "POST",
                body: raw,
                redirect: 'follow'
            });

            let jsonResponse: JsonResponse | undefined;

            try {
                jsonResponse = await res.json();
            } catch (jsonError) {
                console.warn('Error parsing JSON:', jsonError);
                jsonResponse = undefined;
            }

            return { response: res, jsonResponse };

        } catch (error) {
            console.error('Error Validating Email:', error);
            throw error;
        }
    }
}
