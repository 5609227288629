function getCookieValue(name: string): string | null {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
    return null;
}

export class GenericHttp {
    public async POST(url: string, request: any) {
        const myHeaders = new Headers();
        const authToken = getCookieValue('AUTH_TOKEN');

        myHeaders.append(
            "Authorization",
            `Bearer ${authToken}`
        );
        myHeaders.append("Content-Type", "application/json");

        const bodyJson = JSON.stringify(request);

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: myHeaders,
                body: bodyJson,
                redirect: "follow"
            });

            // Log the full response for debugging
            // console.log(response);

            if (response.status === 502) {
                return { error_code: -1, error_message: 'Bad Gateway' };
            }
            if (response.status === 503) {
                return { error_code: -1, error_message: 'Service Unavailable' };
            }
            if (response.status === 504) {
                return { error_code: -1, error_message: 'Gateway Timeout' };
            }
            if (response.status === 401) {
                return { error_code: -1, error_message: 'Unauthorized' };
            }

            return await response.json();
        } catch (error) {
            return { error_code: -1, error_message: 'Network error' };
        }
    }

    public async GET(url: string) {
        const myHeaders = new Headers();

        const authToken = getCookieValue('AUTH_TOKEN');

        myHeaders.append(
            "Authorization",
            `Bearer ${authToken}`
        );
        
        myHeaders.append("Content-Type", "application/json");

        try {
            const response = await fetch(url, {
                method: "GET",
                headers: myHeaders
            });

            // Log the full response for debugging
            // console.log(response);

            if (response.status === 502) {
                return { error_code: -1, error_message: 'Bad Gateway' };
            }
            if (response.status === 503) {
                return { error_code: -1, error_message: 'Service Unavailable' };
            }
            if (response.status === 504) {
                return { error_code: -1, error_message: 'Gateway Timeout' };
            }
            if (response.status === 401) {
                return { error_code: -1, error_message: 'Unauthorized' };
            }

            return await response.json();
        } catch (error) {
            return { error_code: -1, error_message: 'Network error' };
        }
    }
}
