import { CreateTicketService } from "./services/backend/createTicket.service";
import { DownloadService } from "./services/backend/download.service";
import { MailSenderService } from "./services/backend/mailSender.service";
import { LocationService } from "./services/backend/region.service";
import { OtpSenderService } from "./services/backend/otpSender.service";
import { VerifyOtpService } from "./services/backend/verifyOtp.service";
import { AcademicRegisterService } from "./services/backend/academicRegister.service";
import { WhitepaperService } from "./services/backend/whitepaper.service";
import { CodeSnippetService, OtpService, SecureStringService } from "./services/backend/console.service";
import { SecureNumericService } from "./services/backend/console.service";
import { GenericHttp } from "./services/backend/genericHttp.service";
import { EmailValidatorService } from "./services/backend/emailValidator.service";


export const downloadService: DownloadService = new DownloadService();
export const locationService: LocationService = new LocationService();
export const mailSenderService: MailSenderService = new MailSenderService()
export const createTicketService: CreateTicketService = new CreateTicketService()
export const otpSenderService: OtpSenderService = new OtpSenderService()
export const verifyOtpService: VerifyOtpService = new VerifyOtpService()
export const academicRegisterService: AcademicRegisterService = new AcademicRegisterService()
export const whitepaperService: WhitepaperService = new WhitepaperService()
export const secureStringService: SecureStringService = new SecureStringService()
export const secureNumericService: SecureNumericService = new SecureNumericService()
export const genericHttp:GenericHttp=new GenericHttp()
export const codeSnippetService: CodeSnippetService = new CodeSnippetService()
export const otpService: OtpService = new OtpService()
export const emailValidatorService: EmailValidatorService = new EmailValidatorService()