import { ENVIRONMENT } from "../../environment";

export class OtpSenderService {
    public async EmailOtpSender(email: string) {

        try {
            const response = await fetch(ENVIRONMENT.sendOtpUrl + email,
                {
                    redirect: "follow",
                }
            );
            
            return response;

        } catch (error) {
            // console.error('Error fetching data:', error);
        }

    }
}