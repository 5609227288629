import { ENVIRONMENT } from "../../environment";

export class VerifyOtpService {
    public async VerifyOtp(email: string, otp: number) {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "emailId": `${email}`,
            "otp": otp
        });

        try {
            const response = await fetch(ENVIRONMENT.verifyotpUrl,
                {
                    method: "POST",
                    body: raw,
                    redirect: "follow",
                }
            );

            const json = await response.json();
            
            return json;

        } catch (error) {
            // console.error('Error fetching data:', error);
        }

    }
}