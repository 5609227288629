import React, { useState } from 'react';
import { downloadService } from '../../../App.registration';
import { useOsStore } from '../../../StateManager/OsStore';
import { useThemeStore } from '../../../StateManager/ThemeStore';
import { PLATFORM, THEME } from '../../constants/constants';
import { ROUTES } from '../../constants/routes';

const useNavbarHook = () => {

  const { theme, setTheme } = useThemeStore();
  const { os, setOs } = useOsStore();
  const [isSideMenu, setIsSideMenu] = useState(false);
  const [isProductsDropdown, setIsProductsdDropdown] = useState(false);
  const [isBusinessDropdown, setIsBusinessDropdown] = useState(false);
  const [isTransition, setIsTransition] = useState(false);

  function handleThemetoggle() {

    if (theme == THEME.LIGHT) {
      setTheme(THEME.DARK)
      localStorage.setItem("Theme", THEME.DARK);
    }
    else {
      setTheme(THEME.LIGHT)
      localStorage.setItem("Theme", THEME.LIGHT)
    }
  }

  function handleDownloadClick() {

    if (os == PLATFORM.WIN) {
      // downloadService.downloadZunu(PLATFORM.WIN)
      window.location.href = `${ROUTES.DOWNLOAD}`;
    }

    else if (os == PLATFORM.MAC) {
      // downloadService.downloadZunu(PLATFORM.MAC)
      window.location.href = `${ROUTES.DOWNLOAD}`;
    }

    else if (os == PLATFORM.AND) {
      // downloadService.downloadZunu(PLATFORM.AND)
      window.location.href = `${ROUTES.DOWNLOAD}`;
    }

    else if (os == PLATFORM.IOS) {
      downloadService.downloadZunu(PLATFORM.IOS)
    }
    else {
      window.location.href = `${ROUTES.DOWNLOAD}`;
    }
  }

  function handleHamClick() {
    if (isSideMenu) {
      setIsSideMenu(false)
    }
    else {
      setIsSideMenu(true)
    }
  }

  function closeSideMenu() {
    setIsSideMenu(false);
    handleBusinessDropdownClose()
  }


  function handleProductsDropdownClose() {
    setIsProductsdDropdown(false);
  }

  function handleProductsClick() {
    setIsProductsdDropdown(!isProductsDropdown);
  }

  function handleBusinessDropdownClose() {
    setIsBusinessDropdown(false);
    setIsTransition(false)
  }

  function handleBusinessClick() {
    if (!isBusinessDropdown) {
      setIsBusinessDropdown(true);
      setTimeout(() => {
        setIsTransition(true)
      }, 10)
    }
    else {
      setIsTransition(false)
      setTimeout(() => {
        setIsBusinessDropdown(false)
      }, 400)
    }
  }


  return {
    theme, setTheme,
    os, setOs,
    handleThemetoggle,
    handleDownloadClick,
    isSideMenu, setIsSideMenu,
    handleHamClick, closeSideMenu,
    isProductsDropdown, setIsProductsdDropdown,
    isBusinessDropdown, setIsBusinessDropdown,
    isTransition, setIsTransition,
    handleBusinessDropdownClose,
    handleBusinessClick, handleProductsDropdownClose,
    handleProductsClick,

  }
}

export default useNavbarHook