import { ENVIRONMENT } from "../../environment";

export class CreateTicketService {
    public async CreateTicket(formData: any) {

        console.log('[CREATING TICKET]')

        try {
            const res = await fetch(ENVIRONMENT.baseUrl + ENVIRONMENT.createTicketUrl,
                {
                    method: "POST",
                    body: formData,
                    redirect: 'follow'
                }
            );
            
            // const parsedRes = await res.json();
            const parsedRes = await res
            return parsedRes

        } catch (error) {
            console.error('Error Creating Ticket:', error);
        }
    }
}