import React, { FC, useEffect, useState } from "react";
import Navbar from "../_shared/components/Navbar/navbar.component";
import { Footer } from "../_shared/components/Footer/footer.component";
import useLayout from "./layout.hook";
import styles from "./layout.module.scss";
import { Outlet, useLocation } from "react-router-dom";
import useAppHook from "../App.hook";
import { useDownloadBtnStore } from "../StateManager/DownloadBtnStore";
import { ROUTES } from "../_shared/constants/routes";


const Layout: FC<{ children: React.ReactNode }> = ({ children }) => {

	const { isFooter, setIsFooter } = useLayout();
	const [isProfilePage, setIsProfilePage] = useState(false);
	const { isDownloadBtn, setIsDownloadBtn } = useDownloadBtnStore();

	const location = useLocation()
	useEffect(() => {

		if (location.pathname.startsWith('/profile/')) {
			setIsProfilePage(true)
			setIsFooter(false)
		}
	}, [])

	type Routes = { [key: string]: string | Routes };

	const flattenRoutes = (obj: Routes): string[] => {
		const routes: string[] = [];
		const getRoutes = (o: Routes) => {
			Object.values(o).forEach((value) => {
				if (typeof value === 'object') {
					getRoutes(value);
				} else {
					routes.push(value);
				}
			});
		};
		getRoutes(obj);
		return routes;
	};

	// const businessRoutes = flattenRoutes(ROUTES.SOLUTIONS);


	return (
		<div className={`${styles.layoutContainer}`}>
			{
				<>
					{!isProfilePage && <Navbar />}
				</>
			}


			<div className={`${styles.main} ${isProfilePage ? `${styles.profileLayout}` : ''}`}>
				<Outlet />
			</div>

			<>
				{isFooter &&
					<Footer />
				}
			</>


		</div>
	);
};

export default Layout;
