import { toast } from "react-toastify";
import { ENVIRONMENT } from "../../environment";
import { PLATFORM } from "../../_shared/constants/constants";
import { ROUTES } from "../../_shared/constants/routes";

export class DownloadService {


    public async geturl(os: string) {

        try {
            const res = await fetch(ENVIRONMENT.baseUrl + ENVIRONMENT.zunuDownloadUrls.builds + `/${os.toLowerCase()}`,
                {
                    method: "GET",
                    redirect: "follow"
                })

            const parsedRes = await res;
            const jsonRes = await res.json()

            if (res.status === 200) {
                console.log(jsonRes.url);
                return jsonRes.url
            }
            else {
                toast.error("Something went wrong while downloading.")
                return ''
            }

        }
        catch (error) {
            toast.error("Something went wrong while downloading.")
            return ''
        }
    }

    public async downloadZunu(platform: PLATFORM) {
        let downloadUrl: string = '';

        if (platform === PLATFORM.WIN) {
            downloadUrl = await this.geturl(PLATFORM.WIN);
        }
        else if (platform === PLATFORM.MAC) {
            downloadUrl = ENVIRONMENT.zunuDownloadUrls.mac;
        }
        else if (platform === PLATFORM.MACIntel) {
            downloadUrl = ENVIRONMENT.zunuDownloadUrls.mac;
        }
        else if (platform === PLATFORM.AND) {
            downloadUrl = ENVIRONMENT.zunuDownloadUrls.android;
        }
        else if (platform === PLATFORM.INDUS) {
            downloadUrl = ENVIRONMENT.zunuDownloadUrls.indus;
        }
        else if (platform === PLATFORM.IOS) {
            downloadUrl = ENVIRONMENT.zunuDownloadUrls.ios;
        }
        else if (platform === PLATFORM.LINUX18) {
            downloadUrl = await this.geturl(PLATFORM.LINUX18);
        }
        else if (platform === PLATFORM.LINUX22) {
            downloadUrl = await this.geturl(PLATFORM.LINUX22);
        }
        else if (platform === PLATFORM.WIN_PORATBLE) {
            downloadUrl = await this.geturl(PLATFORM.WIN_PORATBLE);
        }

        if (downloadUrl !== '') this.download(downloadUrl);
    }

    private download(downloadUrl: string) {
        try {
            const a = document.createElement('a');
            const url = downloadUrl;
            a.target = "_blank";
            a.href = url;
            a.download = url.split('/').pop() || 'download'; // Provide a default value if splitting doesn't work
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
        catch (error) {
            toast.error("Something went wrong while downloading.")
        }
    }
}