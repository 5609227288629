import { genericHttp } from "../../App.registration";
import { EncryptedResult } from "../../_core/client/Common/Response/EncryptedResult";
import { GenerateKeyResult } from "../../_core/client/Common/Response/GenerateKeyResult";
import { StringEncrArg } from "../../_core/client/Common/Response/StringEncrArg";
import { DecryptArg } from "../../_core/client/Common/Response/DecryptArg";
import { ENVIRONMENT } from "../../environment";
import { DecryptedStringResult } from "../../_core/client/Common/Response/DecryptedStringResult";
import { IntEncrArg } from "../../_core/client/Common/Response/IntEncrArg";
import { DoubleEncrArg } from "../../_core/client/Common/Response/DoubleEncrArg";
import { DecryptIntResult } from "../../_core/client/Common/Response/DecryptIntResult";
import { DecryptDoubleResult } from "../../_core/client/Common/Response/DecryptDoubleResult";
import { EncryptedOperationArgs } from "../../_core/client/Common/Response/EncryptedOperationArgs";
import { StringSearchResult } from "../../_core/client/Common/Response/StringSearchResult";
import { StringTokenResult } from "../../_core/client/Common/Response/StringTokenResult";
import { CompareResult } from "../../_core/client/Common/Response/CompareResult";
import { GetOperationResult } from "../../_core/client/Common/Response/GetOperationResult";
import { CodeSnippetArg } from "../../_core/client/Common/Response/CodeSnippetArg";
import { CodeSnippetResult } from "../../_core/client/Common/Response/CodeSnippetResult";
import { SendOtpArgs } from "../../_core/client/Common/Response/SendOtpArgs";
import { RestResult } from "../../_core/client/Common/Response/RestResult";
import { VerifyOtpArgs } from "../../_core/client/Common/Response/VerifyOtpArgs";
import { VerifyOtpResult } from "../../_core/client/Common/Response/VerifyOtpResult";

export class SecureStringService {
    public async GenerateKey(): Promise<GenerateKeyResult> {
        return await genericHttp.GET(ENVIRONMENT.consoleAppUrls.baseUrl + ENVIRONMENT.consoleAppUrls.generateStringKey);
    }

    public async Encrypt(input: StringEncrArg): Promise<EncryptedResult> {
        return await genericHttp.POST(ENVIRONMENT.consoleAppUrls.baseUrl + ENVIRONMENT.consoleAppUrls.encryptString, input);
    }
    public async Decrypt(input: DecryptArg): Promise<DecryptedStringResult> {
        return await genericHttp.POST(ENVIRONMENT.consoleAppUrls.baseUrl + ENVIRONMENT.consoleAppUrls.decryptString, input);
    }

    /* String Operations */
    public async GetAllOperations(): Promise<GetOperationResult> {
        return await genericHttp.GET(ENVIRONMENT.consoleAppUrls.baseUrl + ENVIRONMENT.consoleAppUrls.stringOperations);
    }

    public async PrefixMatch(input: EncryptedOperationArgs): Promise<StringSearchResult> {
        return await genericHttp.POST(ENVIRONMENT.consoleAppUrls.baseUrl + ENVIRONMENT.consoleAppUrls.prefixMatch, input);
    }
    public async SuffixMatch(input: EncryptedOperationArgs): Promise<StringSearchResult> {
        return await genericHttp.POST(ENVIRONMENT.consoleAppUrls.baseUrl + ENVIRONMENT.consoleAppUrls.suffixMatch, input);
    }
    public async SubstringMatch(input: EncryptedOperationArgs): Promise<StringSearchResult> {
        return await genericHttp.POST(ENVIRONMENT.consoleAppUrls.baseUrl + ENVIRONMENT.consoleAppUrls.equalToMatch, input);
    }
    public async EqualToMatch(input: EncryptedOperationArgs): Promise<StringSearchResult> {
        return await genericHttp.POST(ENVIRONMENT.consoleAppUrls.baseUrl + ENVIRONMENT.consoleAppUrls.equalToMatch, input);
    }
    public async CompareString(input: EncryptedOperationArgs): Promise<CompareResult> {
        return await genericHttp.POST(ENVIRONMENT.consoleAppUrls.baseUrl + ENVIRONMENT.consoleAppUrls.compareString, input);
    }

    public async ConcatenateString(input: EncryptedOperationArgs): Promise<EncryptedResult> {
        return await genericHttp.POST(ENVIRONMENT.consoleAppUrls.baseUrl + ENVIRONMENT.consoleAppUrls.concatenateString, input);
    }

    public async SplitString(input: EncryptedOperationArgs): Promise<StringTokenResult> {
        return await genericHttp.POST(ENVIRONMENT.consoleAppUrls.baseUrl + ENVIRONMENT.consoleAppUrls.splitString, input);
    }

    public async SortStrings(input: string[]): Promise<StringTokenResult> {
        return await genericHttp.POST(ENVIRONMENT.consoleAppUrls.baseUrl + ENVIRONMENT.consoleAppUrls.sortString, input);
    }

    public async ExecuteOperation(input: EncryptedOperationArgs, path: string): Promise<StringSearchResult> {
        return await genericHttp.POST(ENVIRONMENT.consoleAppUrls.baseUrl + '/string/operation' + path, input);
    }

}

export class SecureNumericService {
    public async GenerateKey(): Promise<GenerateKeyResult> {
        return await genericHttp.GET(ENVIRONMENT.consoleAppUrls.baseUrl + ENVIRONMENT.consoleAppUrls.generateNumericKey);
    }

    public async EncryptInteger(input: IntEncrArg): Promise<EncryptedResult> {
        return await genericHttp.POST(ENVIRONMENT.consoleAppUrls.baseUrl + ENVIRONMENT.consoleAppUrls.encryptInteger, input);
    }
    public async DecryptInteger(input: DecryptArg): Promise<DecryptIntResult> {
        return await genericHttp.POST(ENVIRONMENT.consoleAppUrls.baseUrl + ENVIRONMENT.consoleAppUrls.decryptInteger, input);
    }

    public async EncryptDouble(input: DoubleEncrArg): Promise<EncryptedResult> {
        return await genericHttp.POST(ENVIRONMENT.consoleAppUrls.baseUrl + ENVIRONMENT.consoleAppUrls.encryptDouble, input);
    }
    public async DecryptDouble(input: DecryptArg): Promise<DecryptDoubleResult> {
        return await genericHttp.POST(ENVIRONMENT.consoleAppUrls.baseUrl + ENVIRONMENT.consoleAppUrls.decryptDouble, input);
    }
    /* Math Operations */
    public async GetAllOperations(): Promise<GetOperationResult> {
        return await genericHttp.GET(ENVIRONMENT.consoleAppUrls.baseUrl + ENVIRONMENT.consoleAppUrls.numericOperations);
    }

    public async AddInteger(input: EncryptedOperationArgs): Promise<EncryptedResult> {
        return await genericHttp.POST(ENVIRONMENT.consoleAppUrls.baseUrl + ENVIRONMENT.consoleAppUrls.addInterger, input);
    }
    public async SubtractInteger(input: EncryptedOperationArgs): Promise<EncryptedResult> {
        return await genericHttp.POST(ENVIRONMENT.consoleAppUrls.baseUrl + ENVIRONMENT.consoleAppUrls.subtractInteger, input);
    }
    public async MultiplyInteger(input: EncryptedOperationArgs): Promise<EncryptedResult> {
        return await genericHttp.POST(ENVIRONMENT.consoleAppUrls.baseUrl + ENVIRONMENT.consoleAppUrls.multiplyInteger, input);
    }
    public async CompareInteger(input: EncryptedOperationArgs): Promise<CompareResult> {
        return await genericHttp.POST(ENVIRONMENT.consoleAppUrls.baseUrl + ENVIRONMENT.consoleAppUrls.compareInteger, input);
    }
    public async AddDouble(input: EncryptedOperationArgs): Promise<EncryptedResult> {
        return await genericHttp.POST(ENVIRONMENT.consoleAppUrls.baseUrl + ENVIRONMENT.consoleAppUrls.addDouble, input);
    }
    public async SubtractDouble(input: EncryptedOperationArgs): Promise<EncryptedResult> {
        return await genericHttp.POST(ENVIRONMENT.consoleAppUrls.baseUrl + ENVIRONMENT.consoleAppUrls.subtractDouble, input);
    }
    public async MultiplyDouble(input: EncryptedOperationArgs): Promise<EncryptedResult> {
        return await genericHttp.POST(ENVIRONMENT.consoleAppUrls.baseUrl + ENVIRONMENT.consoleAppUrls.multiplyDouble, input);
    }
    public async CompareDouble(input: EncryptedOperationArgs): Promise<CompareResult> {
        return await genericHttp.POST(ENVIRONMENT.consoleAppUrls.baseUrl + ENVIRONMENT.consoleAppUrls.compareDouble, input);
    }

    public async ExecuteOperation(input: EncryptedOperationArgs, path: string): Promise<EncryptedResult> {
        return await genericHttp.POST(ENVIRONMENT.consoleAppUrls.baseUrl + '/numeric/operation' + path, input);
    }
}

export class CodeSnippetService {
    public async getCodeSnippet(url: string): Promise<CodeSnippetResult> {
        return await genericHttp.GET(url);
    }
}

export class OtpService {
    public async sendOtp(input: SendOtpArgs): Promise<RestResult> {
        return await genericHttp.POST(ENVIRONMENT.consoleAppUrls.baseUrl + ENVIRONMENT.consoleAppUrls.sendOtp, input);
    }

    public async verifyOtp(input: VerifyOtpArgs): Promise<VerifyOtpResult> {
        return await genericHttp.POST(ENVIRONMENT.consoleAppUrls.baseUrl + ENVIRONMENT.consoleAppUrls.verifyOtp, input);
    }
}
